<script>
import httpClient from "@/services/http.service";

export default {
  name: "SyllabusDocumentHistory",
  data(){
    return{
      syllabus_id: this.$route.query.syllabus_id,
      syllabusDocHistory: [],
      reason:{
        modalVisible: false,
        comment: ''
      }
    }
  },
  methods:{
    async getSyllabusDocHistory(syllabus_id){
        try {
          const {status, data} = await httpClient.get(`syllabus_data/syllabus-sign/get-statuses?syllabus_id=${syllabus_id}`)
          if (status === 200) {
            console.log('getSyllabusDocHistory', data)
            this.syllabusDocHistory = data
          }
        } catch (e) {
          console.log(e)
        }
    },
    openReason(comment){
      console.log('dada')
      this.reason.comment = comment
      this.reason.modalVisible = true
    },
    closeReason(comment){
      this.reason ={
        modalVisible: false,
        comment: ''
      }
    }
  },
  async mounted() {
    await this.getSyllabusDocHistory(this.syllabus_id)
  }
}
</script>

<template>
  <div class="container">
    <div v-if="syllabusDocHistory.length > 0">
      <h4 class="text-center my-3">История силлабуса</h4>
      <DataTable :value="syllabusDocHistory" tableStyle="min-width: 50rem" paginator :rows="10"
                 paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                 :rowsPerPageOptions="[10, 20, 50]" currentPageReportTemplate="Показано {first}-{last} из {totalRecords}">
        <Column header="№">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>
        <Column field="full_name" header="Name"></Column>
        <Column header="Quantity">
          <template #body="{data}">
            <p v-if="data.status === '0'" class="font-weight-bold text-secondary m-0">Ожидает</p>
            <p v-if="data.status === '1'" class="font-weight-bold text-success m-0">Подтверждено</p>
            <div class="d-flex" v-if="data.status === '2'">
              <p class="font-weight-bold text-danger m-0">Отказано</p>
              <div class="ms-2 d-flex" @click="openReason(data.comment)">
                <p class="font-weight-bold text-primary text-decoration-underline my-0 d-flex align-items-start"
                   style="cursor: pointer;">Причина</p>
                <span class="text-primary" style="font-size: 12px; cursor: pointer">?</span>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <h4 v-else class="text-center my-3">У силлабуса нет истории</h4>
  </div>

  <Dialog v-model:visible="reason.modalVisible"  :closable="false" modal header="Причина отказа" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <span>{{reason.comment}}</span>
    <template #footer>
      <Button label="Закрыть" class="p-button-secondary MT-3" @click="closeReason"/>
    </template>
  </Dialog>
</template>

<style scoped>

</style>